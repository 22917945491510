export const environment = {
      domainId: 33,
domainName: 'Corporacion Ingenium UBA',
PORT: 4033,
  production: true,

  url: 'https://load.neuronweb.online',
  uriBanner: 'https://load.neuronweb.online/file/banner/',
  uriProduct: 'https://load.neuronweb.online/file/product/',
  uriCatalogue: 'https://load.neuronweb.online/file/catalogue/',
  uriImg: 'https://load.neuronweb.online/file/parameter/',
  PWA: true,
  defaultImg: './assets/admin/defaultImage.png',
  whatsapp: 'https://api.whatsapp.com/send?phone=17867185949?&text=Hola podrias darme información.',
  stripePublishKey: 'pk_live_51NbqxmCJhLLQZkJfXAx65aDs0h3im27n0RUwoAezUgh1xLZ6vV6Xy43JKep6jcOkU5CfL3yXNcKqrOBO5MDlbiyW00mC9en6VN',
  ckConfig: 
  "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard,embedbase," +
  "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
  "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
  "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
  "font,format,forms,horizontalrule,htmlwriter,iframe,indent," +
  "indentblock,indentlist,justify,link,list,liststyle,magicline," +
  "newpage,pagebreak,pastefromword,pastetext,preview,print," +
  "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
  "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
  "tabletools,templates,toolbar,undo,wysiwygarea",
  firebaseConfig: {
    apiKey: "AIzaSyBUqBWZ6zhp3ge0MdD902QMWFp698VZ6dA",
    authDomain: "neuron-web-online.firebaseapp.com",
    projectId: "neuron-web-online",
    storageBucket: "neuron-web-online.appspot.com",
    messagingSenderId: "559396221040",
    appId: "1:559396221040:web:d3ec9cd55d6ce43bc9c4b5",
    measurementId: "G-C8FR37Q2JX",
    vapidKey: "BHpxAlAxuZ-_7WEmnTnYLhNNIFaehrYi-nD5mTZa1UYkeHdyC_ynxQ9ySOOR4PZfuFM-pz1NFOh9cTn-aHiMIac"

  },
};
